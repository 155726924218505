import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-layout.main-float',
  templateUrl: './auth-layout.component.html'
})
export class AuthLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
